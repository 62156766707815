<template>
<div class="transferHome">
  <div></div>
  <div class="tran_Top">
    <img @click="$router.go(-1)" src="../icons/svg/fanhui.svg" />
    <div>{{list.int_name}}</div>
    <img src="../icons/svg/fanhui.svg" />
  </div>

  <div class="tran_conen">
    <div class="tran_conen_left">
      <div>
        <img :src="list.imageurl" alt="" />
        <div v-if="list.mainExpalainurl" class="viods">
          <video ref="videos" style="width:100%;height:100%;" :src="list.mainExpalainurl" class="avatar video-avatar" id="videos1" controls="controls" />
        </div>
      </div>
      <div class="tran_Bottom_left">
        <div>产品详情</div>
        <div></div>
        <div v-for="item in list.explainList" :key="item.url">
          <img v-show="item.type != 'mp4'" class="tran_Bottom_leftImg" :src="item.url" alt="">
          <!-- object-fit: none; -->
          <video ref='videocontent' v-show="item.type == 'mp4'" style="width:100%;height:800px;" :src="item.url" class="avVodes avatar video-avatar" controls="controls" />
        </div>

      </div>
    </div>
    <div class="tran_conen_right">
      <div>简介</div>
      <div v-html="list.content"></div>
      <div>相关文档</div>
      <div class="tran_mid_wd wd_hs">
        <div v-for="item in list.fileList" :key="item.url">
          <a class="tagA" download :href="item.url" target="downloadFile">
            <div>
              <img v-if="item.type == 'mp4'" :src="tubiao.MP4" alt="">
              <img v-else-if="item.type == 'pdf'" :src="tubiao.pdf" alt="">
              <img v-else-if="item.type == 'docx'" :src="tubiao.DOCX" alt="">
              <img v-else-if="item.type == 'pptx'" :src="tubiao.ppt" alt="">
              <img v-else :src="tubiao.wenj" alt="">
              <span>{{item.name}}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="lianjie">
        <div>产品链接</div>
        <div>
          <a target="view_window" :href="list.demon_url">{{list.demon_url}}</a>
        </div>
      </div>
      <div class="zhanweihssd" style="width:100%;"></div>
      <div class="tran_Bottom_right hssd">
        <div>项目案例及相关</div>
        <div @click="hel_sub(index)" v-for="(item,index) in list.caseInfo" :key="item.case_id" class="tran_Bottom_right_Bin">
          <img :src="item.img_url" alt="" />
          <div>{{item.case_name}}</div>
        </div>
        <div class="zhanweifu"></div>
      </div>
    </div>
  </div>
  <!-- 
  <div class="tran_Mid">
    <div>
      <img :src="list.imageurl" alt="" />
      <div v-if="list.mainExpalainurl" class="viods">
        <video ref="videos" style="width:100%;height:100%;" :src="list.mainExpalainurl" class="avatar video-avatar" id="videos1" controls="controls" />
      </div>
    </div>
    <div>
      <div>简介</div>
      <div v-html="list.content"></div>
      <div>相关文档</div>
      <div class="tran_mid_wd">
        <div @click="yvlan(item)" v-for="item in list.fileList" :key="item.url">
          <img v-if="item.type == 'mp4'" :src="tubiao.MP4" alt="">
          <img v-else-if="item.type == 'pdf'" :src="tubiao.pdf" alt="">
          <img v-else-if="item.type == 'docx'" :src="tubiao.DOCX" alt="">
          <img v-else :src="tubiao.wenj" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="tran_Bottom">
    <div class="tran_Bottom_left">
      <div>产品详情</div>
      <div></div>
      <img v-for="item in list.explainList" :key="item.url" class="tran_Bottom_leftImg" :src="item.url" alt="" />
    </div>
    <div class="tran_Bottom_right">
      <div>项目案例及相关</div>
      <div @click="hel_sub(index)" v-for="(item,index) in list.caseInfo" :key="item.case_id" class="tran_Bottom_right_Bin">
        <img :src="item.img_url" alt="" />
        <div>{{item.case_name}}</div>
      </div>
      <div class="zhanweifu"></div>
    </div>
  </div> -->
</div>
</template>

<script>
import {
  detailsIntroduction
} from "../api/phonepage";
export default {
  data() {
    return {
      ruleForm: null,
      list: {},
      Isviods: false,
      tubiao: {
        DOCX: require('../SVG/DOCX.svg'),
        MP4: require('../SVG/MP4.svg'),
        pdf: require('../SVG/pdf.svg'),
        wenj: require('../SVG/wenj.svg'),
        ppt: require('../SVG/ppt.svg'),
        xianqin: require('../SVG/xiangqing.svg')
      },
    };
  },

  computed: {},
  components: {},
  created() {},
  mounted: function () {
    this.ruleForm = this.$route.query
    console.log(this.$route.query);
    this.getList()

  },
  methods: {
    scroll() {
      if (this.$refs['videocontent']) {
        this.$refs['videocontent'].forEach(ref => {
          if (this.isEleVisible(ref)) {
            // debugger
            // this.curVideo = ref;
            // this.$refs.a123.click();
            // console.log(ref)
            this.$nextTick(() => {
              ref.play()
              ref.loop = true
            })
            // let myVideos = this.$refs.videos
            //   myVideos.play()
            //   myVideos.loop = true
          } else {
            this.$nextTick(() => {
              ref.pause();
            })
          }
        })
      }
    },
    playVideo() {
      this.curVideo.play();
      this.curVideo.loop = true;
    },
    isEleVisible(ele) {
      var {
        top,
        right,
        bottom,
        left
      } = ele.getBoundingClientRect()
      var w = window.innerWidth
      var h = window.innerHeight
      if (bottom < 0 || top > h) {
        // y 轴方向
        return false
      }
      if (right < 0 || left > w) {
        // x 轴方向
        return false
      }
      return true
    },
    getList() {
      detailsIntroduction({
        int_id: this.ruleForm.int_id
      }).then(d => {
        console.log(d.data)
        // let ce = {
        //   type: 'pdf',
        //   name: 'ces.pdf',
        //   case_name:'cess1',
        //   img_url:'https://manage-eihoo.eihoo.com/proxy/static/uploads/comment/20220402/5010d04e9ae37418c3e5c2fe88bde8c9.jpg',
        // }
        // d.data.mainExpalainurl = ''
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.fileList.push(ce)
        // d.data.caseInfo = []
        // d.data.caseInfo.push(ce)
        this.list = d.data
        this.$nextTick(() => {
          document.addEventListener("scroll", this.scroll)
        })
        if (this.list.mainExpalainurl) {
          this.$nextTick(() => {
            let myVideos = this.$refs.videos
            myVideos.play()
            myVideos.loop = true
          })
        }
      })
    },
    hel_sub(index) {
      this.ruleForm.index = index
      this.$router.push({
        name: `transfer_subpage`,
        query: this.ruleForm,
      });
    },
    viodsStop() {
      let myVideos = this.$refs.videos
      if (this.Isviods) {
        myVideos.pause()
        this.Isviods = false
      } else {
        this.Isviods = true
        this.$nextTick(() => {
          let myVideos = this.$refs.videos
          myVideos.play()
        })
      }
    },
    yvlan(item) {
      window.open(item.url, '_blank')
    },
  },
  beforeDestroy() {
    let myVideos = this.$refs.videos
    myVideos.pause()
  }

};
</script>

<style scoped>
.lianjie {
  padding: 0 20px;
  font-size: 32px;
  color: #222222;
  font-weight: bold;
  background: #fff;
}

.lianjie>div:nth-of-type(2) {
  margin: 10px 0 20px 0px;
}

.lianjie>div:nth-of-type(1) {
  font-size: 32px !important;
  color: #222222 !important;
  font-weight: bold !important;
}

.lianjie div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #409eff;
  cursor: pointer;
}

.lianjie a {
  font-size: 14px;
  color: #409eff;
  cursor: pointer;

}

.transferHome {
  min-height: 100vh;
  background-color: #f8f8f8;
}

.transferHome>div:nth-of-type(1) {
  width: 100%;
  height: 44px;
  background: #ffffff;
}

.tran_Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 25px 40px;
  box-sizing: border-box;
}

.tran_Top>img {
  width: 50px;
}

.tran_Top>img:nth-of-type(2) {
  opacity: 0;
}

.tran_Top>div {
  font-size: 36px;
  font-weight: 700;
  color: #000000;
}

.tran_Mid {
  width: 100%;
  height: 788px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.tran_Mid>div:nth-of-type(1) {
  width: 72.91%;
  height: 100%;
  position: relative;
}

.tran_Mid>div:nth-of-type(1)>img:nth-of-type(1) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bofan {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  z-index: 100;
  /* border: 1px solid #fff; */
  background-color: rgba(0, 0, 0, .5);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bofan>img {
  width: 30px;
}

.viods {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: #000;
}

.clone {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 102;
  width: 40px;
}

.tran_Mid>div:nth-of-type(2) {
  width: 27%;
  height: 100%;
  background: #ffffff;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.tran_Mid>div:nth-of-type(2)>div:nth-of-type(1) {
  font-size: 34px;
  font-weight: bold;
  color: #222222;

}

.tran_Mid>div:nth-of-type(2)>div:nth-of-type(2) {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  margin-top: 0px;
  height: 450px;
  overflow: auto;
  margin-bottom: 15px;

}

.tran_Mid>div:nth-of-type(2)>div:nth-of-type(3) {
  font-size: 34px;
  font-weight: bold;
  color: #222222;
}

.tran_mid_wd {
  /* height: 190px; */
  overflow: auto;
}

.tran_mid_wd>div {
  display: flex;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  align-items: center;
  margin-top: 17px;
}

.tran_mid_wd>div>span {
  width: 14em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tran_mid_wd>div>img {
  width: 36px;
  margin-right: 12px;
}

.tran_Bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 40px;
  box-sizing: border-box;
}

.tran_Bottom_left {
  width: 72.91%;
}

.tran_Bottom_left>div:nth-of-type(2) {
  width: 100%;
  height: 4px;
  background-color: #f8b900;
  box-sizing: border-box;
}

.tran_Bottom_left>div:nth-of-type(1) {
  width: 220px;
  padding-top: 13px;
  padding-bottom: 9px;
  background: #f8b900;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.tran_Bottom_leftImg {
  width: 100%;
  box-sizing: border-box;
  line-height: 0;
  vertical-align: top;
  /* margin-top: 20px; */
}

.avVodes {
  box-sizing: border-box;
  line-height: 0;
  vertical-align: top;
}

.tran_Bottom_right {
  width: 25%;
}

.tran_Bottom_right>div:nth-of-type(1) {
  width: 100%;
  padding: 13px 0;
  background: #f8b900;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.tran_Bottom_right_Bin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  box-sizing: border-box;
  background-color: #FFF;
}

.tran_Bottom_right_Bin>img {
  width: 440px;
}

.tran_Bottom_right_Bin>div {
  width: 440px;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: #222222;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.zhanweifu {
  height: 24px;
  width: 100%;
  background: #FFF;
}

.tran_conen {
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;
  margin-top: 40px;
}

.tran_conen_left {
  width: 72.91%;
}

.tran_conen_left>div:nth-of-type(1) {
  width: 100%;
  height: 788px;
  position: relative;
}

.tran_conen_left>div:nth-of-type(1)>img:nth-of-type(1) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tran_conen_left>div:nth-of-type(2) {
  width: 99.9%;
  margin-top: 40px;

}

.tran_conen_right {
  width: 27%;
  /* background: #ffffff; */
  padding: 0px 0px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.tran_conen_right>div:nth-of-type(1) {
  font-size: 34px;
  font-weight: bold;
  color: #222222;
  padding: 0 20px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #FFF;

}

.tran_conen_right>div:nth-of-type(2) {
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  margin-top: 0px;
  /* height: 450px; */
  overflow: auto;
  padding-bottom: 15px;
  /* margin-bottom: 15px; */
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #FFF;

}

.tran_conen_right>div:nth-of-type(3) {
  font-size: 34px;
  font-weight: bold;
  color: #222222;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #FFF;
}

.wd_hs {
  padding: 0 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #FFF;
}

.hssd {
  width: 100%;
  padding-left: 40px;
  box-sizing: border-box;
  background-color: #F8F8F8;

}

.zhanweihssd {
  width: 100%;
  height: 40px;
  background: #F8F8F8;

}

img {
  border-style: none;
}

/* width: 72.91%; */
</style>
